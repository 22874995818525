import { Button } from '@sgme/ui';
import { type KeyboardEvent, useState } from 'react';

type SimpleEmailsInputProps = {
  emails: string[];
  onChange: (emails: string[]) => void;
};

export const SimpleEmailsInput = (props: SimpleEmailsInputProps) => {
  const { emails, onChange } = props;

  const [newEmail, setNewEmail] = useState('');

  const onRemoveEmail = (email: string) => {
    onChange(emails.filter((currentEmail) => currentEmail !== email));
  };

  const onKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.key === 'Tab') {
      event.preventDefault();

      if (!isEmail(newEmail)) {
        return;
      }

      if (!emails.includes(newEmail)) {
        onChange([...emails, newEmail]);
      }

      setNewEmail('');
    }
  };

  return (
    <div className="picker-label overflow-hidden d-flex flex-wrap flex-grow-1 justify-content-start align-items-center mt-n1 mb-0">
      {emails.map((email) => (
        <EmailItem key={email} email={email} onRemove={onRemoveEmail} />
      ))}

      <input
        type="text"
        value={newEmail}
        onChange={(event) => setNewEmail(event.target.value)}
        onKeyDown={onKeyPress}
        placeholder="Add email"
        autoComplete="off"
        size={1}
        className="mt-1 text-primary py-0 bg-transparent border-0 flex-grow-1"
      />
    </div>
  );
};

const isEmail = (value: string) => {
  return EMAIL_RFC_5322_PATTERN.test(value);
};

const EMAIL_RFC_5322_PATTERN =
  /^((?:[A-Za-z0-9!#$%&'*+\-\/=?^_`{|}~]|(?<=^|\.)"|"(?=$|\.|@)|(?<=".*)[ .](?=.*")|(?<!\.)\.){1,64})(@)((?:[A-Za-z0-9.\-])*(?:[A-Za-z0-9])\.(?:[A-Za-z0-9]){2,})$/gm;

// -------------------------------------

type EmailItemProps = {
  email: string;
  onRemove: (email: string) => void;
};

export const EmailItem = (props: EmailItemProps) => {
  const { email, onRemove } = props;

  const remove = () => {
    onRemove(email);
  };

  return (
    <div className="bg-lvl1 flex-center item badge badge-dismissible badge-default sgbs-badge-default me-1 mr-1 mt-1 badge-md overflow-hidden">
      <Button purpose="link" size="sm" className="text-secondary" onClick={remove}>
        <i className="icon">close</i>
      </Button>

      <span className="text-regular">{email}</span>
    </div>
  );
};
