import { isDefined } from '@sgme/fp';
import { getFakeUser, sgwtConnect } from '../../api/connect';
import type { OptionLegSavedTile, SavedTile } from '../../api/workspaceService/updaters/updater9/model';
import { workspaceServiceEndPoint } from '../../api/workspaceService/workspaceService';
import { getApiUrl } from '../../bootstrap/sgmeConfiguration';
import { type SharedTab, getSharedTabToSave } from '../../epics/metaSelectors/workspace/saveWorkspaceSelectors';
import type { FxForwardAccumulatorInputs } from '../fxAccumulators/fxAccumulatorsModel';
import type { FxAmericanForwardInputs, HedgeType } from '../fxAmericanForward/model/fxAmericanForwardProductModel';
import type { IFxCashInputs } from '../fxCashs/fxCashsModel';
import type { IFxOptionInputs } from '../fxOptions/model/optionProduct';
import type { IFxOptionTypedStrategyLegInputs, IFxVanillaLegInputs } from '../fxOptions/model/optionsLegs';
import type { IFxSwapInputs } from '../fxSwaps/fxSwapsModel';
import type { Thunk } from '../index';
import {
  clientWorkspaceTabAdded,
  clientWorkspaceTileDeleted,
  clientWorkspaceTileRestored,
  saveWorkspaceDone,
  saveWorkspaceReady,
  saveWorkspaceRequested,
} from './clientWorkspaceActions';
import { isProductAuthorized, mapToInstrumentChoice } from './clientWorkspaceThunks';

export function loadAllSharedTabsThunk(): Thunk<void> {
  return async (dispatch, getState, { actionCreators: ac, selectors: sl }) => {
    const allSharedTabIds = await getAPIAllSharedTabIds();

    const state = getState();
    const ccyPairs = sl.getAllCcyPairs(state);
    const productTypes = sl.getAvailableInstruments(state);
    const productsAccess = sl.getProductsAccess(state);

    const isOptionGrouped = sl.getUserPreferenceData(state).optionStrategyGroupLegs;
    const isOptionGreekAndMktExpanded = sl.getUserPreferenceData(state).optionExpandGreekAndMkt;

    for (const tabId of allSharedTabIds) {
      const tab = await getAPISharedTab(tabId);

      if (tab) {
        if (!isDefined(tab.clientId)) {
          console.error(`loaded tab "${tabId} has no clientId"`);
          return;
        }

        dispatch(
          clientWorkspaceTabAdded(tabId, 'tiles', tab.tabName, tab.clientId, tab.ownerEmail, tab.sharedWithEmails),
        );

        const allTilesWithCurrency = tab.tiles
          .sort(({ position: a }, { position: b }) => a.top - b.top)
          .filter(
            (savedTile) =>
              productTypes.includes(mapToInstrumentChoice(savedTile)) &&
              isProductAuthorized(productsAccess, savedTile.productName),
          )
          .filter(({ currencyPair }) => !currencyPair || ccyPairs[currencyPair] !== undefined);

        for (const tile of allTilesWithCurrency) {
          const { tileId, ...rawSavedTile } = tile;
          const savedTile = rawSavedTile as SavedTile;

          dispatch(clientWorkspaceTileRestored(tabId, tileId, savedTile, isOptionGrouped, isOptionGreekAndMktExpanded));
        }

        const allRestoredTiles = allTilesWithCurrency.map((tile) => [tile.tileId, tile] as const);

        dispatch(ac.restoreTileThunk(allRestoredTiles));
        dispatch(ac.sharedTaSynchronizationReady(tabId));
      }
    }
  };
}

export function saveSharedTabThunk(tabId: string): Thunk<void> {
  return async (dispatch, getState, { actionCreators: _ac, selectors: sl }) => {
    dispatch(saveWorkspaceRequested());

    const currentTab = getSharedTabToSave(tabId, sl)(getState());

    if (isDefined(currentTab.sharedWithEmails) && currentTab.sharedWithEmails.length > 0) {
      await saveAPISharedTab(tabId, currentTab);
    }

    dispatch(saveWorkspaceDone(true));

    setTimeout(() => {
      dispatch(saveWorkspaceReady());
    }, 2000);
  };
}

export function stopTabSharingThunk(tabId: string): Thunk<void> {
  return async (_dispatch, _getState, { actionCreators: _ac, selectors: _sl }) => {
    await stopSharingAPISharedTab(tabId);
  };
}

// -------------------------------------

export function syncSharedTabWithRemoteThunk(tabId: string): Thunk<void> {
  return async (dispatch, getState, { actionCreators: ac, selectors: sl }) => {
    const tab = await getAPISharedTab(tabId);

    const state = getState();

    // const ccyPairs = sl.getAllCcyPairs(state);
    // const productTypes = sl.getAvailableInstruments(state);
    // const productsAccess = sl.getProductsAccess(state);

    const isOptionGrouped = sl.getUserPreferenceData(state).optionStrategyGroupLegs;
    const isOptionGreekAndMktExpanded = sl.getUserPreferenceData(state).optionExpandGreekAndMkt;

    const allNewTiles = [] as [string, SavedTile][];

    dispatch(ac.sharedTaSynchronizationPause(tabId));

    const currentTab = state.clientWorkspace.tabs[tabId];
    const isTabExisted = currentTab !== undefined;

    if (!isTabExisted) {
      dispatch(
        clientWorkspaceTabAdded(tabId, 'tiles', tab.tabName, tab.clientId, tab.ownerEmail, tab.sharedWithEmails),
      );
    } else {
      if (currentTab.name !== tab.tabName) {
        dispatch(ac.clientWorkspaceTabRenamed(tabId, tab.tabName));
      }

      if (isDefined(tab.clientId) && isDefined(currentTab.clientId) && currentTab.name !== tab.clientId) {
        dispatch(ac.clientWorkspaceClientChanged(tabId, tab.clientId, currentTab.clientId));
      }
    }

    for (const tile of tab.tiles) {
      const currentTile = state.clientWorkspace.tiles[tile.tileId]; // can't use the selector, because the tile must exist

      if (isDefined(currentTile)) {
        // update the existing tile
        switch (tile.instrument) {
          case 'Cash':
            {
              const cashInputs: Partial<IFxCashInputs> = {
                currencyPair: tile.currencyPair,
                amount: isDefined(tile.amount) ? String(tile.amount) : undefined,
                amountCurrency: tile.amountCurrency,
                maturityDateTenor: tile.maturityDateTenor,

                isNonDeliverable: tile.isNonDeliverable,

                // TODO: now saved today in the workspace
                // fixingSource: tile.fixingSource,
                // fixingCurrency: tile.fixingCurrency,
                // xCurrency: tile.xCurrency,
                // sndFixingSource: tile.sndFixingSource,
              };

              dispatch(ac.cashPropertiesChanged(tile.tileId, cashInputs));
            }
            break;

          case 'Swap':
            {
              const swapInputs: Partial<IFxSwapInputs> = {
                isNonDeliverable: tile.isNonDeliverable,
                currencyPair: tile.currencyPair,
                amountCurrency: tile.amountCurrency,
                nearPaymentDateTenor: tile.nearPaymentDateTenor,
                farPaymentDateTenor: tile.farPaymentDateTenor,
                farAmount: isDefined(tile.farAmount) ? String(tile.farAmount) : undefined,
                nearAmount: isDefined(tile.nearAmount) ? String(tile.nearAmount) : undefined,

                // TODO: now saved today in the workspace
                // isUneven: tile.isUneven,
                // isOffMarket: tile.isOffMarket,
                // nearPaymentDate: tile.nearPaymentDate,
                // farPaymentDate: tile.farPaymentDate,
                // fixingSource: tile.fixingSource,
                // xCurrency: tile.xCurrency,
                // sndFixingSource: tile.sndFixingSource,
                // nearPriceReference: tile.nearPriceReference,
                // farPriceReference: tile.farPriceReference,
              };

              dispatch(ac.swapPropertyChanged(tile.tileId, swapInputs));
            }
            break;

          case 'Option':
            {
              const optionInputs: Partial<IFxOptionInputs> = {
                currencyPair: tile.currencyPair,
                hedgeType: tile.hedgeType,
                premiumDate: tile.premiumDate,
                premiumDateTenor: tile.premiumDateTenor,

                // TODO: now saved today in the workspace
                // markupCurrency: tile.markupCurrency,
                // amountReference: tile.amountReference,
                // isInFine: tile.isInFine,
              };

              dispatch(ac.optionPropertyChanged(tile.tileId, optionInputs));

              for (const [legId, _leg] of Object.entries(tile.legs)) {
                const leg = _leg as OptionLegSavedTile; // so bad type...

                const legInputs =
                  leg?.productName === 'Vanilla'
                    ? ({
                        productName: leg.productName,
                        expiryDate: leg.expiryDate,
                        notionalAmount: isDefined(leg.notionalAmount) ? String(leg.notionalAmount) : undefined,
                        notionalCurrency: leg.notionalCurrency,
                        expiryDateTenor: leg.expiryDateTenor,
                        settlementType: leg.settlementType,
                        cashSettlementCurrency: leg.cashSettlementCurrency,
                        fixingReference1: leg.fixingReference1,
                        marketPlace: leg.marketPlace,
                        side: leg.side,

                        // TODO: now saved today in the workspace
                        // strike: leg.strike,
                        // optionType: leg.optionType,
                        // deliveryDate: leg.deliveryDate,
                        // premiumDate: leg.premiumDate,
                        // premiumDateTenor: leg.premiumDateTenor,
                        // premiumBid: leg.premiumBid,
                        // premiumAsk: leg.premiumAsk,
                        // isInFine: leg.isInFine,
                      } satisfies Partial<IFxVanillaLegInputs>)
                    : ({
                        productName: leg.productName,

                        // TODO: now saved today in the workspace
                        // legIds: leg.legIds,
                      } satisfies Partial<IFxOptionTypedStrategyLegInputs>);

                dispatch(ac.optionLegPropertyChanged(tile.tileId, `${tile.tileId}/${legId}`, legInputs));
              }
            }
            break;

          case 'Accumulator':
            {
              const accumulatorInputs: Partial<FxForwardAccumulatorInputs> = {
                currencyPair: tile.currencyPair,
                premiumDate: tile.premiumDate,
                premiumDateTenor: tile.premiumDateTenor,
                way: tile.way,
                amount: tile.amount,
                amountCurrency: tile.amountCurrency,
                leverage: tile.leverage,
                strike: tile.strike,
                strikeDown: tile.strikeDown,
                pivot: tile.pivot,
                strikeUp: tile.strikeUp,
                step: tile.step,
                ekiUp: tile.ekiUp,
                ekiDown: tile.ekiDown,
                akoTrigger: tile.akoTrigger,
                ekiTrigger: tile.ekiTrigger,
                targetProfitType: tile.targetProfitType,
                target: tile.target,
                firstFixingDate: tile.firstFixingDate,
                firstFixingDateTenor: tile.firstFixingDateTenor,
                priceType: tile.priceType,
                priceCurrency: tile.priceCurrency,
                hedgeType: tile.hedgeType,
                fixingFrequency: tile.fixingFrequency,
                settlementFrequency: tile.settlementFrequency,
                fixingReference1: tile.fixingReference1,
                fixingReference2: tile.fixingReference2,
                settlementMode: tile.settlementMode,
                cashSettlementCurrency: tile.cashSettlementCurrency,
                expiryDate: tile.expiryDate,
                expiryTenor: tile.expiryTenor,

                // TODO: now saved today in the workspace
                // leverageAmount: tile.leverageAmount,
                // hedgeCurrency: tile.hedgeCurrency,
                // hedgeAmount: tile.hedgeAmount,
                // hedgePrice: tile.hedgePrice,
                // numberOfFixings: tile.numberOfFixings,
                // markupCurrency: tile.markupCurrency,
                // schedule: tile.schedule,
              };

              dispatch(ac.accumulatorPropertiesChanged(tile.tileId, accumulatorInputs));
            }
            break;

          case 'AmericanForward': {
            const americanForwardInputs: Partial<FxAmericanForwardInputs> = {
              currencyPair: tile.currencyPair,
              side: tile.side,
              notionalAmount: isDefined(tile.notionalAmount) ? String(tile.notionalAmount) : undefined,
              notionalCurrency: tile.notionalCurrency ?? undefined,
              callabilityStart: tile.callabilityStart,
              callabilityStartTenor: tile.callabilityStartTenor,
              expiryDate: tile.expiryDate,
              expiryDateTenor: tile.expiryDateTenor,
              deliveryDate: tile.deliveryDate,
              deliveryDateTenor: tile.deliveryDateTenor,
              marketPlace: tile.marketPlace,
              hedgeType: (tile.hedgeType ?? undefined) as HedgeType | undefined,
              hedgeRate: tile.hedgeRate,
              hedgeAmount: tile.hedgeAmount,
              hedgeCurrency: tile.hedgeCurrency ?? undefined,
              // TODO:
              // premiumTypeString: tile.premiumTypeString,
              // priceCurrency: tile.priceCurrency ?? undefined,
              premiumPaymentAmount: tile.premiumPaymentAmount,
              premiumDate: tile.premiumDate,
              premiumDateTenor: tile.premiumDateTenor,
              markupCurrency: tile.markupCurrency ?? undefined,

              // TODO: now saved today in the workspace
              // priceType: tile.priceType,
              // premiumCurrency: tile.premiumCurrency,
              // premiumBid: tile.premiumBid,
              // premiumAsk: tile.premiumAsk,
              // premiumPaymentDate: tile.premiumPaymentDate,
              // forwardRate: tile.forwardRate,
            };

            dispatch(ac.americanForwardPropertiesChanged(tile.tileId, americanForwardInputs));
          }
        }
      } else {
        // create a tile
        const { tileId, ...rawSavedTile } = tile;
        const savedTile = rawSavedTile as SavedTile;

        dispatch(clientWorkspaceTileRestored(tabId, tileId, savedTile, isOptionGrouped, isOptionGreekAndMktExpanded));

        allNewTiles.push([tileId, rawSavedTile]);
      }

      // remove deleted tiles
      const allCurrentTileIds = state.clientWorkspace.tabs[tabId]?.tiles ?? [];
      const allNewTileIds = tab.tiles.map((tile) => tile.tileId);

      const allDeletedTileIds = allCurrentTileIds.filter((currentTileId) => !allNewTileIds.includes(currentTileId));

      for (const deletedTileId of allDeletedTileIds) {
        dispatch(clientWorkspaceTileDeleted(deletedTileId, tabId));
      }
    }

    dispatch(ac.restoreTileThunk(allNewTiles));
    dispatch(ac.gridLayoutFullupdate(tabId, tab.tiles));
    dispatch(ac.sharedTaSynchronizationReady(tabId));

    // case 'CASH_PROPERTIES_CHANGED':
    // case 'SWAP_PROPERTIES_CHANGED':
    // case 'OPTION_PROPERTY_CHANGED':
    // case 'BULK_PROPERTY_CHANGED':
    // case 'ACCUMULATOR_PROPERTIES_CHANGED':
    // case 'ORDER_PROPERTY_CHANGED':
  };
}

// -------------------------------------

export function removeSharedTabFromRemoteThunk(tabId: string): Thunk<void> {
  return (dispatch, _getState, { actionCreators: ac, selectors: _sl }) => {
    dispatch(ac.clientWorkspaceTabClosedThunk(tabId));
  };
}

// -------------------------------------

/**
 * WHY FETCH HERE AND NOT INSIDE SRC/API/WORKSPACESERVICE/WORKSPACESERVICE.TS ?
 * Because RxJS is too complex, add too many boilerplates and will be removed
 */

type APIAllSharedTabIdsResponse = string[];

const getAPIAllSharedTabIds = () => {
  return getApi(`${ENDPOINT_URL}shared/all`).then((response) => response.json() as Promise<APIAllSharedTabIdsResponse>);
};

// -------------------------------------

type APISharedTabResponse = SharedTab;

const getAPISharedTab = (tabId: string) => {
  return getApi(`${ENDPOINT_URL}shared/${tabId}`).then((response) => response.json() as Promise<APISharedTabResponse>);
};

// -------------------------------------

const saveAPISharedTab = (tabId: string, sharedTab: SharedTab) => {
  return postApi(`${ENDPOINT_URL}shared/${tabId}`, sharedTab).then((response) => response.json() as Promise<true>);
};

// -------------------------------------

const stopSharingAPISharedTab = (tabId: string) => {
  return deleteApi(`${ENDPOINT_URL}shared/${tabId}`).then((response) => response.json() as Promise<true>);
};

// -------------------------------------

// see src/utils/sgmeHttp.ts

const ENDPOINT_URL = `${getApiUrl()}${workspaceServiceEndPoint}`;

const getApi = (url: string) =>
  fetch(url, {
    method: 'GET',
    headers: getFetchHeaders(),
  });

const postApi = <TBody>(url: string, body: TBody) =>
  fetch(url, {
    method: 'POST',
    headers: { ...getFetchHeaders(), 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });

const deleteApi = (url: string) =>
  fetch(url, {
    method: 'DELETE',
    headers: { ...getFetchHeaders(), 'Content-Type': 'application/json' },
  });

const getFetchHeaders = () => ({
  Application: 'FX',
  ...fakeAuthenticationHeader(),
  Authorization: sgwtConnect.getAuthorizationHeader() as string,
});

const fakeAuthenticationHeader = (): HeadersInit => {
  const fakeUser = getFakeUser();

  return fakeUser !== undefined && window.sgmeConfiguration.useFakeSgConnect
    ? { 'X-Fake-Authentication': fakeUser }
    : {};
};
