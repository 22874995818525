import { button } from 'components/Form/Inputs/e2e';
import { connect } from 'react-redux';
import type { DispatchWithThunkExt } from 'state';
import { clientWorkspaceNewDefaultTileAddedThunk } from 'state/clientWorkspace/clientWorkspaceThunks';
import type { GridItemPosition } from 'state/gridLayout/gridLayoutModels';
import styled from 'styled-components';
import { COLUMN_WIDTH, defaultCashHeight } from 'styles/constants';
import { v4 } from 'uuid';
import { gridItemContainerClassName } from './GridItem';

interface INewTileOwnProps {
  position: GridItemPosition;
  gridId: string;
}

interface INewTileDispatchProps {
  addTile: () => void;
}

type NewTileProps = INewTileOwnProps & INewTileDispatchProps;

const AddTileButton = styled.button`
  opacity: 0;

  &:hover {
    opacity: 1;
  }
`;

const AddTileContainer = styled.div.attrs({
  className: `${gridItemContainerClassName} p-1 btn-group`,
})`
  height: ${defaultCashHeight}px;
  width: ${COLUMN_WIDTH}px;
`;

export const NewTileRaw: React.FunctionComponent<NewTileProps> = ({ position, addTile }) => (
  <AddTileContainer style={{ ...position }}>
    <AddTileButton
      type="button"
      className="btn btn-flat-secondary h-100 w-100"
      onClick={addTile}
      data-e2e={button('newTile')}
    >
      <i className="icon icon-xl">add_circle_outline</i>
    </AddTileButton>
  </AddTileContainer>
);

// ///////////////////////////////////////////

function mapDispatchToProps(
  dispatch: DispatchWithThunkExt,
  { position, gridId }: INewTileOwnProps,
): INewTileDispatchProps {
  return {
    addTile: () => {
      dispatch(clientWorkspaceNewDefaultTileAddedThunk(gridId, v4(), position));
    },
  };
}

export const NewTile = connect(null, mapDispatchToProps)(NewTileRaw);
